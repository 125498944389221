<template lang="pug">
modal(size='xs' @close='$emit("close")')
  modal-header(icon='download' class='mb-5') 
    span(v-if='inquiryId') {{ $t('Produkte aus Anfrage exportieren als:') }}
    span(v-else) {{ $t('Produkte aus Projekt exportieren als:') }}
  overlay(:visible='loading' class='!rounded-xl') 

  btn(secondary data-test='btn-project-export-csv' class='mb-2 !rounded-xl !p-4' @click='onClickExportAsCSV()') 
    icon(name='custom-file-csv' class='mr-3 text-3xl text-purple-500')
    span(class='font-normal') {{ $t('Kommagetrennte Werte (.csv)') }}

  btn(secondary data-test='btn-project-export-xls' class='!rounded-xl !p-4' @click='onClickexportAsXls()') 
    icon(name='custom-file-excel' class='mr-3 text-3xl text-purple-500')
    span(class='font-normal') {{ $t('Microsoft Excel (.xlsx)') }}
</template>

<script setup lang="ts">
import { useExport } from '../composables'
import { useInquiriesStore } from '@/pages/inquiries/store'
import { useProjectsStore } from '@/pages/projects/store'
import { ref } from 'vue'

const createFileName = (prefix: string, id: string) => `${prefix}_${id}_${new Date().toISOString().slice(0, 10)}`

const emit = defineEmits(['close'])
const { exportAsCsv, exportAsXls } = useExport()
const store = useProjectsStore()
const inqStore = useInquiriesStore()
const props = defineProps<{ inquiryId?: string; projectId: string }>()
const loading = ref(false)

const onClickExportAsCSV = () => {
  if (props.inquiryId) {
    exportAsCsv(createFileName('nuucon_inquiry', props.inquiryId), inqStore.allInquiriesAsMap[props.inquiryId].items)
  } else {
    exportAsCsv(createFileName('nuucon_project', props.projectId), store.project(props.projectId).items)
  }
  emit('close')
}
const onClickexportAsXls = async () => {
  try {
    loading.value = true
    if (props.inquiryId) {
      await exportAsXls(
        createFileName('nuucon_inquiry', props.inquiryId),
        inqStore.allInquiriesAsMap[props.inquiryId].items,
      )
    } else {
      await exportAsXls(createFileName('nuucon_project', props.projectId), store.project(props.projectId).items)
      emit('close')
    }
  } catch (e) {}
  loading.value = false
}
</script>
